import styled from "styled-components"
import { Square } from "../icons"
import { breakpoint } from "../../../styles/global-style"

export const Tooltip = (props) => {
    return (
        <TooltipContainer {...props} isDisabled={true} data-tip={props.tip}>
            <img src="/assets/icons/question_mark.svg" width={6} height={9} alt="ikona" />
        </TooltipContainer>
    )
}

export const TooltipContainer = styled(Square)`
    width: 22px;
    height: 22px;
    padding: 0;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 11;
    ${breakpoint("620px")} {
        width: 16px;
        height: 16px;
    }
`
