export const errorMessages = {
    required: "Povinné pole",
    email: {
        typeError: "To se nám nezdá. Určitě je e-mail správný?",
        invalid: "To se nám nezdá. Určitě je e-mail správný?"
    },
    password: {
        short: "Tvé heslo je moc krátké",
        easy: "Tvé heslo je snadné uhodnout",
        match: "Hesla se musí shodovat"
    },
    name: {
        short: "Opravdu jde o jméno?"
    },
    username: {
        short: "Uživatelské jméno je příliš krátké",
        inUse: "Toto uživatelské jméno je již použité. Vyber si jiné."
    },
    phone: {
        typeError: "Chybný formát telefonu",
        short: "Telefonní číslo je moc krátké"
    },
    unexpexed_error: "Nastala neočekávaná chyba",
    subscription: {
        cancel: "Tvé předplatné bylo zrušeno",
        renew: "Tvé předplatné bylo obnoveno"
    }
}

export const firebaseErrors = {
    "auth/email-already-exists": "Tento e-mail je již používán",
    "auth/email-already-in-use": "Tento e-mail je již používán",
    "auth/internal-error": "Nastala chyba",
    "auth/invalid-email": "Neplatný email",
    "auth/invalid-email-verified": "Chyba při ověření emailu",
    "auth/invalid-password": "Neplatné heslo",
    "auth/weak-password": "Slabé heslo",
    "auth/operation-not-allowed": "Nepovolená operace",
    "auth/project-not-found": "Projekt nenalezen",
    "auth/wrong-password": "Nesprávné heslo",
    "auth/user-not-found": "Neexistující email",
    "auth/user-disabled": "Tento účet byl zablokován",
    "auth/too-many-requests":
        "Příliš mnoho pokusů o přihlášení. Resetujte své heslo pro okamžitý přístup k účtu nebo to zkuste později."
    // "auth/invalid-id-token": "error",
    // "auth/maximum-user-count-exceeded": "error",
    // "auth/missing-uid": "error",
    // "auth/uid-already-exists": "error",
}
