import styled, { css } from "styled-components"
import { color, fontSize, lineHeight, radius, transitionDuration } from "../../../styles/global-style"

export const Square = styled.div`
    width: fit-content;
    border-radius: ${radius.small};
    color: ${color.white};
    background: ${(props) => (props.isDisabled ? color.languid : color.floral)};
    font-size: ${(props) => (props.fontSize ? props.fontSize : "12px")};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : "18px")};
    padding: 0 0.5em;
`

export const EmptySquare = styled(Square)`
    width: 20px;
    height: 20px;
    ${(props) => props.bg && `background: ${props.bg};`}
    ${(props) => props.margin && `margin-left: ${props.margin}px;`}
    ${(props) => props.margin && `margin-right: ${props.margin}px;`}
    ${(props) =>
        props.isActive &&
        css`
            padding: 4px;
            border: 1px solid ${color.languid};
            position: relative;
            background: white;
        `}
    &:after {
        ${(props) =>
            props.isActive &&
            css`
                content: "";
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                border-radius: 2px;
                width: 14px;
                height: 14px;
                background: ${props.bg};
            `}
    }
`

export const LargeSquare = styled(Square)`
    width: 40px;
    height: 40px;
    font-size: ${fontSize.h3};
    line-height: ${lineHeight.h3};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: ${radius.main};
    ${(props) => props.bg && `background: ${props.bg};`}

    ${({ bgTransition}) => bgTransition && css`
        transition: background 0.25s;
    `}
`

export const ExtraLargeSquare = styled(LargeSquare)`
    width: 70px;
    height: 70px;
`
