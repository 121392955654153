import { useEffect, useRef, useState } from "react"
import styled, { css } from "styled-components"
import { color, fontSize, lineHeight, radius, transitionDuration } from "../../../styles/global-style"

const LabelInput = (props) => {
    const [focus, setFocus] = useState(false)
    const inputRef = useRef()
    const labelRef = useRef()

    useEffect(() => {
        const onFocus = () => setFocus(true)
        const onClick = () => inputRef.current.focus()
        const onBlur = () => setFocus(false)
        inputRef.current.addEventListener("focus", onFocus)
        inputRef.current.addEventListener("blur", onBlur)

        labelRef.current.addEventListener("click", onClick)
        labelRef.current.addEventListener("blur", onBlur)

        return () => {
            if (inputRef.current) {
                inputRef.current.removeEventListener("focus", onFocus)
                inputRef.current.removeEventListener("blur", onBlur)
            }
            if (labelRef.current) {
                labelRef.current.removeEventListener("focus", onFocus)
                labelRef.current.removeEventListener("blur", onBlur)
            }
        }
    }, [])

    return (
        <Wrapper asChild={props.asChild} isError={props.isError}>
            <Label
                isFocus={focus || (props.value && props.value.length > 0)}
                ref={labelRef}
                empty={!props.value || (props.value && props.value.length < 1)}
            >
                {props.placeholder}
            </Label>
            <Input
                type={props.type}
                isError={props.isError}
                autoComplete={props.autoComplete}
                isFocus={focus}
                ref={inputRef}
                noEdit={props.noEdit}
                {...props}
            />
        </Wrapper>
    )
}

export default LabelInput

const Wrapper = styled.div`
    position: relative;
    margin-bottom: 20px;
    border-radius: ${radius.small};
    overflow: hidden;
`

const Label = styled.h6`
    color: ${color.philippine};
    position: absolute;
    left: 20px;
    top: 6px;
    transform: translateY(0);
    transition: transform ${transitionDuration}, top ${transitionDuration};
    ${(props) =>
        props.empty &&
        css`
            top: 50%;
            transform: translateY(-50%);
        `}
    ${(props) =>
        props.isFocus &&
        css`
            top: 6px;
            transform: translateY(0);
        `}
    z-index: 10;
`

const Input = styled.input`
    width: 310px;
    height: 45px;
    display: block;
    border-radius: ${radius.small};
    transition: border-color ${transitionDuration}, background-color 600000s 0s, color 600000s 0s !important;
    border: 1px solid ${(props) => (props.isError ? color.red : color.languid)};
    ${({ isFocus }) =>
        isFocus &&
        css`
            border: 1px solid ${color.charleston};
        `}
    background: ${color.white};
    position: relative;
    padding: 18px 20px 0;
    font-size: ${fontSize.h6};
    line-height: ${lineHeight.h6};
    color: ${color.charleston};
    &::placeholder {
        color: transparent;
    }

    ${({ readOnly }) =>
        readOnly &&
        css`
            border: 1px solid ${color.languid};
            color: ${color.philippine};
        `}

    ${({ noEdit }) =>
        noEdit &&
        css`
            cursor: default;
        `}
`
