import styled from "styled-components"
import LoginForm from "../ui/forms/login-form-wrapper"
import { breakpoint, minUnusedHeight, sizes, transitionDuration } from "../../styles/global-style"

const OnboardingWrapper = (props) => {
    return (
        <Wrapper>
            <Content>
                <LogoWrapper>
                    <img src="/assets/background-flash.svg" alt="Logo" />
                </LogoWrapper>
                <LoginForm
                    verticalCenter={props.verticalCenter}
                    controlPanel={props.controlPanel}
                    onBack={props.onBack}
                >
                    {props.children}
                </LoginForm>
            </Content>
        </Wrapper>
    )
}

export default OnboardingWrapper

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    z-index: -1;
    background: linear-gradient(90deg, #c8b6ff 0%, #e7c6ff 100%);
`

const Content = styled.div`
    width: 100%;
    height: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;

    @media screen and (min-height: 940px) {
        align-items: center;
    }

    ${breakpoint(sizes.desktopM)} {
        justify-content: flex-end;
    }
`

const LogoWrapper = styled.div`
    visibility: hidden;
    position: absolute;
    width: 100%;
    max-height: 100%;
    left: 0;

    z-index: 1;

    img {
        max-width: 100%;
        height: 500px;
    }

    ${breakpoint(sizes.desktopM)} {
        width: auto;
        top: 380px;
        visibility: visible;
        left: 20%;
        transform: translate(-50%, -50%);
        transition: left ${transitionDuration};

        @media screen and (min-height: 940px) {
            top: 50%;
        }
    }

    ${breakpoint(sizes.desktopL)} {
        transition: transform ${transitionDuration}, left ${transitionDuration};
        left: 25%;
        transform: translate(-50%, -50%);
    }

    ${breakpoint(sizes.desktopXL)} {
        left: 33%;
    }
`
