import styled, { css } from "styled-components"
import { color, breakpoint, sizes, radius, transitionDuration, minUnusedHeight } from "../../../styles/global-style"
import { BackButton } from "../buttons"

const LoginForm = (props) => {
    return (
        <FormWrapper verticalCenter={props.verticalCenter}>
            {props.controlPanel && (
                <ControlPanel>
                    <BackButton isDisabled={true} onClick={props.onBack} />
                </ControlPanel>
            )}
            <Content>{props.children}</Content>
        </FormWrapper>
    )
}

export default LoginForm

const FormWrapper = styled.div`
    width: 100%;
    min-height: 100vh;
    height: 100%;
    position: relative;
    background: ${color.white};
    z-index: 2;
    text-align: center;
    padding-top: 30px;
    padding-bottom: 50px;
    margin: 0;
    transition: margin ${transitionDuration}, transform ${transitionDuration};
    ${(props) =>
        props.verticalCenter &&
        css`
            display: flex;
            flex-direction: row;
            align-items: center;
        `}

    ${breakpoint("620px")} {
        border-radius: ${radius.large};
        margin: 20px auto;
        max-width: 620px;
        height: 100%;
        min-height: min(calc(100vh - 40px), 900px);
        max-height: 900px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    ${breakpoint(sizes.desktopM)} {
        margin: 20px 4% 20px 0;
    }

    ${breakpoint(sizes.desktopM)} {
        padding-top: 30px;
    }

    ${breakpoint(sizes.desktopL)} {
        margin-right: 25%;
        transform: translateX(50%);
    }

    ${breakpoint(sizes.desktopXL)} {
        margin-right: 33%;
    }
`

const Content = styled.div`
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
    position: relative;
    > * {
        margin-left: auto;
        margin-right: auto;
    }

    ${breakpoint("620")} {
        display: flex;
        flex-direction: column;
    }
`

const ControlPanel = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding-left: 30px;
    padding-top: 30px;
`
