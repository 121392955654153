import Head from "next/head"

const CustomHead = (props) => {
    return (
        <Head>
            {props.title && <title>{props.title} | JEDUNAVÝKON</title>}
            {props.description && <meta name="description" content={props.description} />}
            {props.children && props.children}
        </Head>
    )
}

export default CustomHead
