import styled, { css, keyframes } from "styled-components"
import { color } from "../../../styles/global-style"

export const revealErrorField = keyframes`
    0% {
        max-height: 0;
        overflow: hidden;
    }
    100% {
        max-height: 200px;
        overflow: auto;
    }
`

export const ErrorField = styled.p`
    color: ${color.red};
    font-size: 12px;
    text-align: left;
    margin: -15px 0 10px;
    animation: ${revealErrorField} 0.5s;
`

export const LiteErrorField = styled(ErrorField)`
    margin-top: -18px;
    margin-bottom: 10px;
`

export const SmallErrorField = styled(ErrorField)`
    position: absolute;
`
