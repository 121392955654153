import styled from "styled-components"
import { CustomButton } from "../buttons"
import { createCookie, getSocialAuth } from "../../../utils/auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props"
import GoogleLogin from "react-google-login"
import AppleLogin from "react-apple-login"
import { color } from "../../../styles/global-style"
import { appRoutes } from "../../../constants/app-routes"
import { useEffect, useState } from "react"
import { ErrorField } from "../error-fields"

const SocialAuthSmall = () => {
    const [errorStatus, setErrorStatus] = useState(null)

    const handleFacebook = async (response) => {
        if (response && response.accessToken) {
            try {
                const res = await getSocialAuth(response.accessToken, "Facebook")
                if (res.status === 200) {
                    let resData = res.data.data
                    createCookie(resData.access_token)
                    if (resData.email_verified && resData.profile_completed && resData.active_plans.length > 0) {
                        document.location = appRoutes.DASHBOARD.route
                        return
                    }

                    if (!resData.email_verified) {
                        setErrorStatus("email_not_verified")
                    }

                    if (!resData.profile_completed) {
                        setErrorStatus("profile_not_completed")
                    }

                    if (resData.active_plans.length === 0) {
                        setErrorStatus("no_active_plan")
                    }
                }
            } catch (error) {
                console.error(error)
            }
        }
    }

    const handleGoogle = async (response) => {
        if (response && response.accessToken) {
            try {
                const res = await getSocialAuth(response.tokenId, "Google")
                if (res.status === 200) {
                    let resData = res.data.data
                    createCookie(resData.access_token)
                    if (resData.email_verified && resData.profile_completed && resData.active_plans.length > 0) {
                        document.location = appRoutes.DASHBOARD.route
                        return
                    }

                    if (!resData.email_verified) {
                        setErrorStatus("email_not_verified")
                    }

                    if (!resData.profile_completed) {
                        setErrorStatus("profile_not_completed")
                    }

                    if (resData.active_plans.length === 0) {
                        setErrorStatus("no_active_plan")
                    }
                }
            } catch (error) {
                console.error(error)
            }
        }
    }

    const handleApple = async (response) => {
        var token = ""
        if (response && response.authorization && response.authorization.id_token) {
            token = response.authorization.id_token
        }
        if (response) {
            try {
                const res = await getSocialAuth(token, "Apple")
                if (res.status === 200) {
                    let resData = res.data.data
                    createCookie(resData.access_token)
                    if (resData.email_verified && resData.profile_completed && resData.active_plans.length > 0) {
                        document.location = appRoutes.DASHBOARD.route
                        return
                    }

                    if (!resData.email_verified) {
                        setErrorStatus("email_not_verified")
                    }

                    if (!resData.profile_completed) {
                        setErrorStatus("profile_not_completed")
                    }

                    if (resData.active_plans.length === 0) {
                        setErrorStatus("no_active_plan")
                    }
                }
            } catch (error) {
                console.error(error)
            }
        }
    }

    const errorMessage = (status) => {
        switch (status) {
            case "email_not_verified":
                return "Email nebyl potvrzen"
            case "profile_not_completed":
                return "Profil nevyplněn"
            case "no_active_plan":
                return
            default:
                return
        }
    }

    useEffect(() => {
        if (errorStatus) {
            switch (errorStatus) {
                case "profile_not_completed":
                    return (document.location = appRoutes.ONBOARDING.YOUR_PROFILE.route)
                case "no_active_plan":
                    return (document.location = appRoutes.SUBSCRIPTION_SELECT.route)
                default:
                    return
            }
        }
    }, [errorStatus])

    return (
        <>
            <Wrapper>
                <FacebookLogin
                    appId={process.env.NEXT_PUBLIC_FACEBOOK_SIGN_IN_APP_ID}
                    onClick={handleFacebook}
                    callback={handleFacebook}
                    render={(renderProps) => (
                        <AuthButton onClick={renderProps.onClick} bg={"#4964A8"}>
                            <img src="/assets/icons/login-facebook.svg" alt="ikona" width={20} height={20} />
                        </AuthButton>
                    )}
                />
                <GoogleLogin
                    clientId={process.env.NEXT_PUBLIC_GOOGLE_SIGN_IN_CLIENT_ID}
                    onSuccess={(response) => handleGoogle(response)}
                    onFailure={(response) => handleGoogle(response)}
                    isSignedIn={false}
                    render={(renderProps) => (
                        <AuthButton onClick={() => renderProps.onClick()} bg={"#5180E7"}>
                            <img src="/assets/icons/login-google.svg" alt="ikona" width={20} height={20} />
                        </AuthButton>
                    )}
                />
                <AppleLogin
                    clientId={process.env.NEXT_PUBLIC_APPLE_SIGN_IN_CLIENT_ID}
                    redirectURI="https://app.jedunavykon.cz/prihlaseni"
                    callback={handleApple}
                    responseType={"code id_token"}
                    responseMode="form_post"
                    scope="email"
                    usePopup={true}
                    render={(renderProps) => (
                        <AuthButton onClick={renderProps.onClick} bg={color.charleston}>
                            <img src="/assets/icons/login-apple.svg" alt="ikona" width={20} />
                        </AuthButton>
                    )}
                />
            </Wrapper>
            {errorStatus === "email_not_verified" && (
                <ErrorField style={{ textAlign: "center" }}>{errorMessage("email_not_verified")}</ErrorField>
            )}
        </>
    )
}

export default SocialAuthSmall

const Wrapper = styled.div`
    max-width: 310px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    height: 45px;
    margin-bottom: 30px;
`

const AuthButton = styled(CustomButton)`
    position: relative;
    flex: 0.3;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`
