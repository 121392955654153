import styled from "styled-components"
import { color, radius, transitionDuration } from "../../../styles/global-style"
import { Tooltip } from "../tooltips"
import LabelInput from "./label-input"

export const Input = styled.input`
    display: block;
    width: 100%;
    max-width: 310px;
    border-radius: ${radius.small};
    padding: 12px 20px;
    border: 1px solid ${(props) => (props.isError ? color.red : color.languid)};
    margin-bottom: 20px;
    transition: border-color ${transitionDuration}, background-color 600000s 0s, color 600000s 0s !important;
    &::placeholder {
        color: ${color.philippine};
        font-size: 12px;
        line-height: 18px;
        text-transform: uppercase;
    }
    &:focus {
        border-color: ${color.charleston};
    }
`

export const TooltipInput = (props) => {
    return (
        <Wrapper>
            <LabelInput type={props.type} asChild {...props} value={props.value} />
            {/* <TooltipInside data-place={props.place} tip={props.tip} /> */}
        </Wrapper>
    )
}

const Wrapper = styled.div`
    position: relative;
    width: 100%;
    > input {
        padding-right: 36px;
    }
`

const TooltipInside = styled(Tooltip)`
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
`
